var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('Page-Header',{attrs:{"title":_vm.$t('employees.employees'),"icon":"mdi-account-multiple"}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('search'),"single-line":"","append-icon":"mdi-magnify","outlined":"","dense":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getData($event)},"click:append":_vm.getData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"offset-y":10,"close-on-content-click":false,"close-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',{staticClass:"chips-text-field",attrs:{"outlined":"","placeholder":_vm.filteredDepartments.length == 0
                                        ? _vm.$t('departments.departments')
                                        : '',"single-line":"","dense":"","hide-details":"","append-icon":"mdi-menu-down","append-outer-icon":"mdi-close-circle"},on:{"click":function($event){_vm.departmentsMenuOpen = true},"click:append-outer":function($event){_vm.filteredDepartments = [];
                                    _vm.departmentsMenuOpen = false;
                                    _vm.departmentSearch = '';
                                    _vm.filteredDepartmentsTemp = [];
                                    _vm.getData();}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticStyle:{"text-align":"right"}},[_vm._l((_vm.filteredDepartments.slice(
                                                0,
                                                2
                                            )),function(tag){return _c('v-chip',{key:tag.departmentGuid,attrs:{"small":""}},[_vm._v(" "+_vm._s(tag.departmentName)+" ")])}),(
                                                _vm.filteredDepartments.length >
                                                    2
                                            )?_c('v-chip',{staticClass:"mr-1",attrs:{"outlined":"","small":"","color":"primary"}},[_vm._v(" (+"+_vm._s(_vm.filteredDepartments.length - 2)+" "+_vm._s(_vm.$t("others"))+") ")]):_vm._e()],2)]},proxy:true},{key:"append",fn:function(){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-top":"-4px","margin-bottom":"2px"},attrs:{"link":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-menu-down")])],1)]},proxy:true}],null,true),model:{value:(_vm.departmentSearch),callback:function ($$v) {_vm.departmentSearch=$$v},expression:"departmentSearch"}})]}}]),model:{value:(_vm.departmentsMenuOpen),callback:function ($$v) {_vm.departmentsMenuOpen=$$v},expression:"departmentsMenuOpen"}},[[_c('v-card',[_c('v-card-text',{staticStyle:{"max-height":"50vh","overflow-y":"scroll"}},[_c('v-treeview',{attrs:{"return-object":"","selectable":"","item-key":"departmentGuid","selection-type":"independent","items":_vm.departments,"search":_vm.departmentSearch,"item-text":"departmentName","dense":""},on:{"update:search":function($event){_vm.departmentSearch=$event}},model:{value:(_vm.filteredDepartmentsTemp),callback:function ($$v) {_vm.filteredDepartmentsTemp=$$v},expression:"filteredDepartmentsTemp"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.departmentsMenuOpen = false;
                                            _vm.departmentSearch = '';
                                            _vm.filteredDepartments = _vm.filteredDepartmentsTemp;
                                            _vm.getData();}}},[_vm._v(" "+_vm._s(_vm.$t("done"))+" ")]),_c('v-btn',{attrs:{"color":"red darken-1","dark":""},on:{"click":function($event){_vm.departmentsMenuOpen = false;
                                            _vm.departmentSearch = '';
                                            _vm.filteredDepartmentsTemp = _vm.filteredDepartments;}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)]],2)],1)],1),_c('v-data-table',{attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"options":_vm.options,"item-key":"employeeGuid","show-select":"","server-items-length":_vm.totalRecords,"headers":_vm.headers,"items":_vm.employees,"loading":_vm.loading,"footer-props":{
                    'items-per-page-options': [
                        10,
                        20,
                        100,
                        200,
                        400,
                        800,
                        1000
                    ]
                },"items-per-page":20,"loading-text":_vm.$t('loading')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(
                            _vm.isInRole(
                                _vm.roles.employeesManagement.printPasswords
                            )
                        )?_c('v-btn',{staticClass:"my-2",attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.selected.length},on:{"click":_vm.openQrDialog}},[_c('v-icon',[_vm._v("mdi-qrcode-scan")]),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.$t("printCredentials")))])],1):_vm._e(),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.changeEmployeeStatus,"onClose":_vm.closeChangeStatus,"dialogTitle":_vm.editedItem.lockoutEnabled
                                ? _vm.$t('unblock')
                                : _vm.$t('block'),"toolBarColor":"red darken-1"}})]},proxy:true},{key:"item.departmentName",fn:function(ref){
                                var item = ref.item;
return [_c('div',{style:('color:' + _vm.departmentsColors[item.level])},[_vm._v(" "+_vm._s(item.departmentName)+" ")]),_c('div',{staticStyle:{"font-size":"small","color":"grey"}},[_vm._v(" "+_vm._s(item.departmentNameFull)+" ")])]}},{key:"item.actions",fn:function(ref){
                                var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [(
                                            _vm.isInRole(
                                                _vm.roles.employeesManagement
                                                    .changePassword
                                            )
                                        )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.openChangePasswordDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-key ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("users.changePassword")))])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [(
                                            _vm.isInRole(
                                                _vm.roles.employeesManagement
                                                    .blockStatusChange
                                            )
                                        )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.openChangeStatus(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":item.lockoutEnabled
                                                    ? 'green darken-1'
                                                    : 'red darken-1'}},[_vm._v(" "+_vm._s(item.lockoutEnabled ? "mdi-account-lock-open-outline" : "mdi-account-off-outline")+" ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.lockoutEnabled ? _vm.$t("unblock") : _vm.$t("block")))])])],1)],1)]}},{key:"item.lockoutEnabled",fn:function(ref){
                                                    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.lockoutEnabled ? 'red darken-1' : 'green',"outlined":""},model:{value:(item.lockoutEnabled),callback:function ($$v) {_vm.$set(item, "lockoutEnabled", $$v)},expression:"item.lockoutEnabled"}},[_vm._v(" "+_vm._s(_vm.$t( "employees.lockoutStatus." + item.lockoutEnabled ))+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('Change-Employee-Password',{attrs:{"dialog":_vm.changePasswordDialog,"employeeGuid":_vm.editedItem.employeeGuid,"employeeName":_vm.editedItem.employeeName,"closeDialog":_vm.closeChangePasswordDialog}}),_c('EmployeesQr',{attrs:{"employees":_vm.selected,"dialog":_vm.qrDialog,"closeDialog":_vm.closeQrDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }