import axios from "axios";

export default class DepartmentsApi {
    static GetHighLevelDepartments() {
        return axios
            .get("Departments/GetHighLevelDepartments")
            .then(response => response);
    }

    static Get(parentGuid) {
        return axios
            .get(parentGuid ? "Departments/Get?ParentGuid=" + parentGuid : "Departments/Get")
            .then(response => response);
    }
}
