<template>
    <v-dialog
        scrollable
        max-width="1050"
        flat
        elevation="0"
        persistent
        v-model="dialog"
    >
        <v-card flat elevation="0">
            <v-toolbar flat dark class="mb-5" color="primary">
                <v-card-title>
                    <v-icon>mdi-qrcode-scan</v-icon>
                    <span class="mx-2">{{ $t("printCredentials") }}</span>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-btn @click="print" class="mx-1" outlined
                    ><v-icon>mdi-printer-outline</v-icon
                    >{{ $t("print") }}</v-btn
                >
                <v-btn text depressed @click="closeDialog" class="mx-1">{{
                    $t("close")
                }}</v-btn>
            </v-toolbar>
            <v-card-text>
                <div id="printQr" v-if="isLoaded">
                    <div
                        style="margin: 0;"
                        v-for="(employee, index) in employees"
                        :key="index"
                    >
                        <EmployeeCard
                            ref="`employeeGuid-${employee.employeeGuid}`"
                            :employee="employee"
                        ></EmployeeCard>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import EmployeeCard from "@/views/Employees/EmployeeCard.vue";

export default {
    components: { EmployeeCard },
    props: ["employees", "dialog", "closeDialog"],
    data() {
        return {
            isLoaded: false
        };
    },
    mounted() {},
    watch: {
        dialog(val) {
            if (val) {
                setTimeout(() => (this.isLoaded = true), 100);
            } else {
                this.isLoaded = false;
            }
        }
    },
    methods: {
        print() {
            
            this.$htmlToPaper("printQr", {
                styles: [
                    `data:text/css;base64,Ym9keSB7DQptYXJnaW46IDAgIWltcG9ydGFudDsNCn0=`
                ]
            });
        },
  
    }
};
</script>
<style>
@media print {
    .page-break {
        page-break-after: always;
    }
}
</style>
