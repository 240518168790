<template>
    <v-dialog persistent max-width="600" v-model="dialog">
        <v-card>
            <v-toolbar elevation="0" outlined color="primary" dark>
                {{
                    $t("employees.ui.changeEmployeePassword") +
                        " - " +
                        employeeName
                }}
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="12">
                                <span>* {{ $t("passwordRules") }}</span>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="changePasswordItem.newPassword"
                                    :label="$t('users.password')"
                                    :rules="[rules.required]"
                                    type="password"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    type="password"
                                    v-model="changePasswordItem.confirmPassword"
                                    :rules="[
                                        rules.required,
                                        rules.confirmPassword
                                    ]"
                                    :label="$t('users.confirmPassword')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary darken-1"
                    class="white--text"
                    @click="saveChangePassword"
                    :loading="loading"
                    :disabled="!valid"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{ $t("save") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    class="white--text"
                    text
                    @click="closeDialog"
                    :loading="loading"
                    :disabled="!valid"
                >
                    {{ $t("cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { CheckPasswordValidation } from "../../BusinessLogic/PasswordValidation";
import EmployeesApi from "../../Api/EmployeesApi";

export default {
    props: ["employeeGuid", "employeeName", "dialog", "closeDialog"],
    data() {
        return {
            resetDialog: false,
            searchInput: null,
            employees: [],
            loading: false,
            valid: true,
            changePasswordItem: {
                newPassword: "",
                confirmPassword: ""
            },
            rules: {
                required: value => !!value || this.$t("ThisFieldIsRequired"),
                confirmPassword: () =>
                    this.changePasswordItem.confirmPassword ===
                        this.changePasswordItem.newPassword ||
                    this.$t("users.notMatchPassword")
            }
        };
    },
    methods: {
        saveChangePassword() {
            var val = this.$refs.form.validate();
            if (!val) return;

            var errorMessage = CheckPasswordValidation(
                this.changePasswordItem.newPassword,
                4
            );

            if (errorMessage != null) {
                this.$toast.warning(
                    this.$t("passwordValidation." + errorMessage)
                );
                return;
            }

            this.loading = true;
            EmployeesApi.changeEmployeePassword(
                this.employeeGuid,
                this.changePasswordItem.newPassword,
                this.changePasswordItem.confirmPassword
            )
                .then(response => {
                    if (response.data.status == this.responseStatus.ok) {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                        this.closeDialog();
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    if (
                        e.response.data.status == this.responseStatus.badRequest
                    )
                        this.$toast.warning(this.$t("passwordRules"));
                    else {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
