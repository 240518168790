<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="1000"
        height="705"
        direction="rtl"
        viewBox="0 0 1000 705"
    >
        <defs>
            <clipPath :id="`clip-${employee.employeeGuid}`">
                <rect width="1000" height="705" />
            </clipPath>
            <image
                id="image"
                width="214"
                height="214"
                v-bind="{ 'xlink:href': appQR }"
            />
            <image
                :id="`employee-qr-image-${employee.employeeGuid}`"
                width="214"
                height="214"
                v-bind="{ 'xlink:href': employeeQr }"
            />
        </defs>
        <g
            :id="`employee-card-${employee.employeeGuid}`"
            data-name="Employee Card"
            v-bind="{ 'clip-path': `url(#${employee.employeeGuid})` }"
        >
            <rect width="1000" height="705" fill="#fff" />
            <image
                id="image_2_"
                data-name="image (2)"
                width="1000"
                height="705"
                v-bind="{ 'xlink:href': bg }"
            />
            <!-- wrap the text  -->

            <text
                :id="`name-${employee.employeeGuid}`"
                data-name="name"
                transform="translate(883 211)"
                font-size="30"
                font-family="SegoeUI-Bold, Segoe UI"
                font-weight="700"
            >
                <tspan x="0" y="0">{{ employee.employeeName }}</tspan>
            </text>

            <text
                :id="`position-${employee.employeeGuid}`"
                data-name="position"
                transform="translate(813 278)"
                font-size="25"
                font-family="SegoeUI-Bold, Segoe UI"
                font-weight="700"
            ></text>

            <text
                :id="`username-${employee.employeeGuid}`"
                data-name="username"
                transform="translate(497 465)"
                font-size="20"
                font-family="SegoeUI-Bold, Segoe UI"
                font-weight="700"
                style="direction: ltr"
            >
                <tspan dominant-baseline="middle" text-anchor="middle">
                    {{ employee.employeeCode }}
                </tspan>
            </text>
            <text
                :id="`password-${employee.employeeGuid}`"
                data-name="password"
                transform="translate(497 557)"
                font-size="20"
                font-family="SegoeUI-Bold, Segoe UI"
                font-weight="700"
                style="direction: ltr"
            >
                <tspan dominant-baseline="middle" text-anchor="middle">
                    {{ employee.employeePassword }}
                </tspan>
            </text>
            <text
                :id="`pin-${employee.employeeGuid}`"
                class="text"
                data-name="pin"
                transform="translate(497 665)"
                font-size="20"
                font-family="SegoeUI-Bold, Segoe UI"
                font-weight="700"
            >
                <tspan dominant-baseline="middle" text-anchor="middle">
                    ملاحظة: هذه المعلومات سرية و يجب الحفاظ عليها من الضياع (
                    يرجى تغيير كلمة المرور بعد تسجيل الدخول )
                </tspan>
            </text>
            <use
                :id="`user-qr-${employee.employeeGuid}`"
                data-name="employee-qr"
                transform="translate(19 390)"
                v-bind="{
                    'xlink:href': `#employee-qr-image-${employee.employeeGuid}`
                }"
            />
            <use
                :id="`app-qr-${employee.employeeGuid}`"
                data-name="app-qr"
                transform="translate(760 390)"
                xlink:href="#image" 
            />
        </g>
    </svg>
</template>

<script>
import QRCode from "qrcode";

import bg from "/src/assets/bgBorder.jpg";
export default {
    name: "EmployeeCard",
    props: {
        employee: {
            type: Object,
            default: () => ({
                employeeGuid: "",
                employeeName: "",
                departmentName: "",
                employeeCode: "",
                employeePassword: ""
            })
        }
    },
    computed: {
        bg: () => bg
    },
    data: () => ({
        employeeQr: "",
        appQR: "",
        appQRText: "https://appdownloads.kf.iq/alameedhr"
    }),
    async mounted() {
        this.employeeQr = await QRCode.toDataURL(
            `${this.employee.employeeCode}\n${this.employee.employeePassword}`
        );
        this.appQR = await QRCode.toDataURL(this.appQRText);
        var id = `position-${this.employee.employeeGuid}`;

        this.wrapText(
            id,
            this.employee.departmentNameFull +
            (this.employee.departmentNameFull ? " - " : '') +
                this.employee.departmentName,
            710
        );
    },
    methods: {
        wrapText(id, text, width) {
            const svgNS = "http://www.w3.org/2000/svg";
            const textElement = document.getElementById(id);
            const words = text.split(" ");
            
            let line = "";
            let dy = 1.2;
            let tspan = document.createElementNS(svgNS, "tspan");
            tspan.setAttribute("x", 0);
            tspan.setAttribute("dy", 0 + "em");
            textElement.appendChild(tspan);
            //center text vertically if there is only one line

            var isMutipleLines = false;

            words.forEach(word => {
              
                if (tspan.getComputedTextLength() > width) {
                    isMutipleLines = true;
                    line = word + " ";
                    tspan = document.createElementNS(svgNS, "tspan");
                    tspan.setAttribute("x", 0);
                    tspan.setAttribute("dy", dy + "em");
                    textElement.appendChild(tspan);
                } else {
                    let testLine = line + word + " ";
                    tspan.textContent = testLine;
                    line = testLine;
                }

                tspan.textContent = line;
            });

            if (!isMutipleLines) {
                tspan.setAttribute("dy", 0.5 + "em");
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
