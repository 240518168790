function CheckUppercase(str) {
    for (var i = 0; i < str.length; i++) {
        if (
            str.charAt(i) == str.charAt(i).toUpperCase() &&
            str.charAt(i).match(/[a-z]/i)
        ) {
            return true;
        }
    }
    return false;
}

function CheckLowercase(str) {
    for (var i = 0; i < str.length; i++) {
        if (
            str.charAt(i) == str.charAt(i).toLowerCase() &&
            str.charAt(i).match(/[a-z]/i)
        ) {
            return true;
        }
    }
    return false;
}

function CheckSpecialChar(str) {
    // eslint-disable-next-line no-useless-escape
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(str);
}

function HasNumber(str) {
    return /\d/.test(str);
}

function CheckLength(str, length) {
    return str.length < length ? false : true;
}

function CheckPasswordValidation(str, length) {
    if (!str) return null;
    else if (CheckUppercase(str) == false) {
        return "noCapitalCase";
    } else if (CheckLowercase(str) == false) {
        return "noLowerCase";
    } else if (CheckSpecialChar(str) == false) {
        return "noSpecialCharacter";
    } else if (HasNumber(str) == false) {
        return "noNumber";
    } else if (CheckLength(str, length) == false) {
        return "length";
    } else return null;
}

module.exports = {
    CheckUppercase,
    CheckLowercase,
    CheckSpecialChar,
    HasNumber,
    CheckLength,
    CheckPasswordValidation
};
