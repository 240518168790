<template>
    <v-container fluid>
        <v-card>
            <Page-Header
                :title="$t('employees.employees')"
                icon="mdi-account-multiple"
            >
            </Page-Header>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="search"
                            @keydown.enter="getData"
                            :label="$t('search')"
                            single-line
                            append-icon="mdi-magnify"
                            @click:append="getData"
                            outlined
                            dense
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-menu
                            v-model="departmentsMenuOpen"
                            :offset-y="10"
                            :close-on-content-click="false"
                            :close-on-click="false"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :placeholder="
                                        filteredDepartments.length == 0
                                            ? $t('departments.departments')
                                            : ''
                                    "
                                    single-line
                                    dense
                                    hide-details
                                    class="chips-text-field"
                                    v-model="departmentSearch"
                                    @click="departmentsMenuOpen = true"
                                    append-icon="mdi-menu-down"
                                    append-outer-icon="mdi-close-circle"
                                    @click:append-outer="
                                        filteredDepartments = [];
                                        departmentsMenuOpen = false;
                                        departmentSearch = '';
                                        filteredDepartmentsTemp = [];
                                        getData();
                                    "
                                >
                                    <template v-slot:prepend-inner>
                                        <span style="text-align: right">
                                            <v-chip
                                                v-for="tag in filteredDepartments.slice(
                                                    0,
                                                    2
                                                )"
                                                :key="tag.departmentGuid"
                                                small
                                            >
                                                {{ tag.departmentName }}
                                            </v-chip>

                                            <v-chip
                                                v-if="
                                                    filteredDepartments.length >
                                                        2
                                                "
                                                outlined
                                                class="mr-1"
                                                small
                                                color="primary"
                                            >
                                                (+{{
                                                    filteredDepartments.length -
                                                        2
                                                }}
                                                {{ $t("others") }})
                                            </v-chip>
                                        </span>
                                    </template>
                                    <template v-slot:append>
                                        <v-btn
                                            style="margin-top: -4px;margin-bottom: 2px"
                                            link
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon dense>mdi-menu-down</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </template>
                            <template>
                                <v-card>
                                    <v-card-text
                                        style="max-height: 50vh;overflow-y: scroll"
                                    >
                                        <v-treeview
                                            return-object
                                            selectable
                                            item-key="departmentGuid"
                                            selection-type="independent"
                                            :items="departments"
                                            :search.sync="departmentSearch"
                                            item-text="departmentName"
                                            v-model="filteredDepartmentsTemp"
                                            dense
                                        ></v-treeview>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            @click="
                                                departmentsMenuOpen = false;
                                                departmentSearch = '';
                                                filteredDepartments = filteredDepartmentsTemp;
                                                getData();
                                            "
                                            color="primary"
                                        >
                                            {{ $t("done") }}
                                        </v-btn>
                                        <v-btn
                                            @click="
                                                departmentsMenuOpen = false;
                                                departmentSearch = '';
                                                filteredDepartmentsTemp = filteredDepartments;
                                            "
                                            color="red darken-1"
                                            dark
                                        >
                                            {{ $t("cancel") }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-menu>
                    </v-col>
                </v-row>

                <v-data-table
                    :page="page"
                    :pageCount="numberOfPages"
                    :options.sync="options"
                    item-key="employeeGuid"
                    show-select
                    v-model="selected"
                    :server-items-length="totalRecords"
                    :headers="headers"
                    :items="employees"
                    :loading="loading"
                    :footer-props="{
                        'items-per-page-options': [
                            10,
                            20,
                            100,
                            200,
                            400,
                            800,
                            1000
                        ]
                    }"
                    :items-per-page="20"
                    :loading-text="$t('loading')"
                >
                    <template v-slot:top>
                        <v-btn
                            v-if="
                                isInRole(
                                    roles.employeesManagement.printPasswords
                                )
                            "
                            class="my-2"
                            color="primary"
                            :loading="loading"
                            :disabled="!selected.length"
                            @click="openQrDialog"
                        >
                            <v-icon>mdi-qrcode-scan</v-icon>
                            <span class="mx-2">{{
                                $t("printCredentials")
                            }}</span></v-btn
                        >

                        <!-- user dialog here -->
                        <confirm-dialog
                            :openDialog="dialogDelete"
                            :onClicked="changeEmployeeStatus"
                            :onClose="closeChangeStatus"
                            :dialogTitle="
                                editedItem.lockoutEnabled
                                    ? $t('unblock')
                                    : $t('block')
                            "
                            toolBarColor="red darken-1"
                        ></confirm-dialog>
                    </template>
                    <template v-slot:[`item.departmentName`]="{ item }">
                        <div :style="'color:' + departmentsColors[item.level]">
                            {{ item.departmentName }}
                        </div>

                        <div style="font-size: small;color: grey;">
                            {{ item.departmentNameFull }}
                        </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row>
                            <v-col cols="6">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-if="
                                                isInRole(
                                                    roles.employeesManagement
                                                        .changePassword
                                                )
                                            "
                                            icon
                                            :loading="loading"
                                            @click="
                                                openChangePasswordDialog(item)
                                            "
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>
                                                mdi-key
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{
                                        $t("users.changePassword")
                                    }}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="6">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-if="
                                                isInRole(
                                                    roles.employeesManagement
                                                        .blockStatusChange
                                                )
                                            "
                                            icon
                                            :loading="loading"
                                            @click="openChangeStatus(item)"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon
                                                :color="
                                                    item.lockoutEnabled
                                                        ? 'green darken-1'
                                                        : 'red darken-1'
                                                "
                                            >
                                                {{
                                                    item.lockoutEnabled
                                                        ? "mdi-account-lock-open-outline"
                                                        : "mdi-account-off-outline"
                                                }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{
                                        item.lockoutEnabled
                                            ? $t("unblock")
                                            : $t("block")
                                    }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.lockoutEnabled`]="{ item }">
                        <v-chip
                            :color="
                                item.lockoutEnabled ? 'red darken-1' : 'green'
                            "
                            outlined
                            v-model="item.lockoutEnabled"
                        >
                            {{
                                $t(
                                    "employees.lockoutStatus." +
                                        item.lockoutEnabled
                                )
                            }}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <Change-Employee-Password
            :dialog="changePasswordDialog"
            :employeeGuid="editedItem.employeeGuid"
            :employeeName="editedItem.employeeName"
            :closeDialog="closeChangePasswordDialog"
        />
        <EmployeesQr
            :employees="selected"
            :dialog="qrDialog"
            :closeDialog="closeQrDialog"
        />
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";
import ChangeEmployeePassword from "./ChangeEmployeePassword.vue";
import EmployeesApi from "../../Api/EmployeesApi";
import EmployeesQr from "./EmployeesQr.vue";
import DepartmentsApi from "@/Api/DepartmentsApi";

export default {
    components: {
        ConfirmDialog,
        PageHeader,
        ChangeEmployeePassword,
        EmployeesQr
    },
    data() {
        return {
            selected: [],
            departmentsColors: ["#7F9F80", "#FF204E", "#0062f9", "", "#00BCD4"],
            qrDialog: false,
            page: 1,
            totalRecords: 0,
            numberOfPages: 0,
            options: {},
            changePasswordDialog: false,
            loading: true,
            dialogDelete: false,
            dialogChangePassword: false,
            editedIndex: -1,
            search: "",
            departmentSearch: "",
            empty: "",
            allDepartments: [],
            departments: [],
            filteredDepartments: [],
            filteredDepartmentsTemp: [],
            headers: [
                {
                    text: this.$t("employees.employeeCode"),
                    value: "employeeCode"
                },
                {
                    text: this.$t("employees.employeeName"),
                    value: "employeeName"
                },
                {
                    text: this.$t("departments.departmentName"),
                    value: "departmentName"
                },
                {
                    text: this.$t("positionTypes.position"),
                    value: "employeePositionType"
                },
                {
                    text: this.$t("employees.lockoutEnabled"),
                    value: "lockoutEnabled"
                },
                { text: "", value: "actions" }
            ],
            employees: [],
            editedItem: {
                employeeGuid: null,
                employeeName: null
            },
            defaultItem: {
                employeeGuid: null,
                employeeName: null
            },
            departmentsMenuOpen: false
        };
    },
    watch: {
        options: {
            handler() {
                this.getData();
            }
        }
    },
    mounted() {
        this.getDepartments();
        this.getData();
    },
    methods: {
        getEmployeesPasswords() {
            this.loading = true;
            var guids = this.selected.map(e => e.employeeGuid);
            axios
                .post("Employees/GetEmployeesPasswords", guids)
                .then(response => {
                    this.selected.forEach(i => {
                        var emp = response.data.data
                            .filter(m => m.employeeGuid == i.employeeGuid)
                            .map(x => x.employeePassword);
                        i.employeePassword = emp[0];
                    });
                    this.qrDialog = true;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openQrDialog() {
            if (this.selected.length > 0) {
                this.getEmployeesPasswords();
            }
        },
        closeQrDialog() {
            this.qrDialog = false;
        },
        openChangePasswordDialog(item) {
            Object.assign(this.editedItem, item);
            this.editedIndex = this.employees.indexOf(item);
            this.changePasswordDialog = true;
        },
        closeChangePasswordDialog() {
            this.changePasswordDialog = false;
        },
        openChangeStatus(item) {
            this.editedIndex = this.employees.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        changeEmployeeStatus() {
            try {
                if (this.editedItem.lockoutEnabled) {
                    axios
                        .post("Employees/Unblock", {
                            employeeGuid: this.editedItem.employeeGuid
                        })
                        .then(response => {
                            if (
                                response.data.status == this.responseStatus.ok
                            ) {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.getData();
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.closeChangeStatus();
                        });
                } else {
                    axios
                        .post("Employees/Block", {
                            employeeGuid: this.editedItem.employeeGuid
                        })
                        .then(response => {
                            if (
                                response.data.status == this.responseStatus.ok
                            ) {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.getData();
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.closeChangeStatus();
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.resetValidation += 1;
        },
        closeChangePassword() {
            this.dialogChangePassword = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form1.reset();
            this.$refs.form1.resetValidation();
        },
        closeChangeStatus() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getDepartments() {
            DepartmentsApi.Get(null).then(response => {
                this.allDepartments = response.data.data;
                response.data.data.forEach(department => {
                    if (department.levelIcon > 1) {
                        let parent = response.data.data.find(
                            d =>
                                d.departmentGuid ==
                                department.parentDepartmentGuid
                        );
                        if (parent) {
                            if (parent.children)
                                parent.children.push(department);
                            else parent.children = [department];
                        }
                    }
                });
                this.departments = response.data.data.filter(
                    d => d.levelIcon == 1
                );
                // this.departments = this.allDepartments;
            });
        },
        getData() {
            this.loading = true;

            const { page, itemsPerPage } = this.options;

            let filterDepartments = this.filteredDepartments.sort(
                d => d.levelIcon
            );
            filterDepartments = filterDepartments.filter(
                d =>
                    !this.filteredDepartments.some(
                        d2 => d.parentDepartmentGuid == d2.departmentGuid
                    )
            );
            filterDepartments = filterDepartments.map(d => d.departmentGuid);
            // let minDepartmentLevel = Math.min(this.filteredDepartments.map(d=>d.levelIcon));
            EmployeesApi.GetEmployeesInfo(
                itemsPerPage,
                page,
                this.search,
                filterDepartments
            )
                .then(response => {
                    this.employees = response.data.data.employees;

                    this.employees.forEach(function(e) {
                        e.qr = e.employeeCode + "\n" + e.employeePassword;
                    });

                    this.employees.forEach(e => {
                        e.employeePositionType = this.$t(
                            "positionTypes." + e.employeePositionType
                        );
                    });

                    this.totalRecords = response.data.data.totalRecords;
                    this.numberOfPages = response.data.data.numberOfPages;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        hideDepartmentsMenu() {
            this.$refs.departmentsMenu.close();
        }
    }
};
</script>
<!--<style>-->
<!--.chips-text-field .v-text-field__slot {-->
<!--    display: none !important;-->
<!--}-->
<!--</style>-->
